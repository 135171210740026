// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchStatesRequest from "./requests/FetchStatesRequest.res.js";
import * as UpdateStateRequest from "./requests/UpdateStateRequest.res.js";
import * as FixStateCountsRequest from "./requests/FixStateCountsRequest.res.js";
import * as UpdateStateActiveRequest from "./requests/UpdateStateActiveRequest.res.js";
import * as FetchStatesDashboardRequest from "./requests/FetchStatesDashboardRequest.res.js";
import * as UpdateStateActiveInBatchRequest from "./requests/UpdateStateActiveInBatchRequest.res.js";

var fetchStates = FetchStatesRequest.exec;

var fetchStatesDashboard = FetchStatesDashboardRequest.exec;

var updateActive = UpdateStateActiveRequest.exec;

var fixCounts = FixStateCountsRequest.exec;

var updateActiveInBatch = UpdateStateActiveInBatchRequest.exec;

var update = UpdateStateRequest.exec;

export {
  fetchStates ,
  fetchStatesDashboard ,
  updateActive ,
  fixCounts ,
  updateActiveInBatch ,
  update ,
}
/* FetchStatesRequest Not a pure module */
