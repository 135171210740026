// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as FetchStatesDashboardRequest from "./FetchStatesDashboardRequest.res.js";

function exec() {
  return Rest.$$fetch("/dashboard/states/fix_counts", {
              NAME: "Post",
              VAL: undefined
            }, {
              NAME: "Json",
              VAL: FetchStatesDashboardRequest.Res.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
