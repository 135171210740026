// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as State from "../../../models/State.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

function exec(pageNum, sortBy, query) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "TotalCities" ? "&sort_by=states.cities_count_active&sort_direction=" + toString(sortBy.VAL) : (
      variant === "Name" ? "&sort_by=states.name&sort_direction=" + toString(sortBy.VAL) : (
          variant === "TotalLocations" ? "&sort_by=states.locations_count_active&sort_direction=" + toString(sortBy.VAL) : "&sort_by=countries.name&sort_direction=" + toString(sortBy.VAL)
        )
    );
  return Rest.$$fetch("/locations/states?" + (page + (query$1 + sortBy$1)), "Get", {
              NAME: "Json",
              VAL: State.Index.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
